import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useUser } from "../context/UserContext"; 
import axios from 'axios';

const SAMLResponseHandler = () => {
  const location = useLocation();
  const getQueryParams = (queryString) => {
    return new URLSearchParams(queryString);
  };
  const { user, setUser } = useUser();
  useEffect(() => {
    let queryParams = getQueryParams(location.search);
    let en_eid = queryParams.get('eid');
    let en_data = queryParams.get('email');
    if(en_eid && en_data)
    {
      var payload = {
        'eid' : en_eid,
        'email' : en_data
      }
      axios.post(`${process.env.REACT_APP_SERVER_NEW}/saml/jwt`, payload)
      .then(response => {
        const data = response.data;
        if(data.token && data.token != null)
        {
          var token = data.token;
          if(token)
          {
            sessionStorage.setItem('saml_demo_authToken', token);
          }
        }
        else
        {
          location.reload()
        }
      })
      .catch(error => {
        console.error('Error verifying SAML response', error);
      });
    }
    else
    {
      axios.post(`${process.env.REACT_APP_SERVER_NEW}/demo/auth`, {
        origin: window.location.origin
      }).then(response => {
        if(response && response.data && response.data.url)
        {
            window.location.href = response.data.url
        }
        else
        {
            alert("Please try again later");
        }
      }).catch((error) => {
        console.log(error)
      })
    }
  }, [location]);

  return (
    <div></div>
  );
};

export default SAMLResponseHandler;
