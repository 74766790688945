import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import { useContext, useMemo } from "react";
import { AppContext } from '../context/AppContext';
import { ImageContext } from '../context/ImageContext';
import { chooseImages, mapIndexToColor } from '../utils';
import ProductGalleryFooter from "./ProductGalleryFooter";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ZoomItem from "../zoomernew/zoom";
import html2canvas from 'html2canvas';
import "./ProductGallery.css"
import { mapColorToIndex } from '../utils';
import { MessageBannerContext } from "../context/MessageBannerContext";
import { HeartOutlined, HeartFilled, EditFilled } from "@ant-design/icons";
import { fetchIsLiked, fetchPostLike } from "../utils/fetch";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { enhanceImageClarity } from '../utils/enhanceImageClarity';

const ProductGallery = forwardRef(({ onChange, setToggled, setToggleActivated, currentIndex, setCurrentIndex, changeFromMug, isZoomEnabled, setIsZoomEnabled }, ref) => {
  const { apparel, color, setColor, prompt } = useContext(AppContext);
  const [slideIndex, setSlideIndex] = useState(0);
  // const [currentIndex, setCurrentIndex] = useState(mapColorToIndex(apparel, color));
  const { generatedImage, isLiked, setIsLiked, editedImage, setEditedImage } = useContext(ImageContext);
  const editedImageRef = useRef(null);
  // const imageList = useMemo(() => chooseImages(apparel || 'tshirt'), [apparel]);
  const imageList = useMemo(() => {
    const userType = process.env.REACT_APP_USERTYPE;
    const chosenApparel = userType === 'STUDENT' ? 'student-tshirt' : (apparel || 'tshirt');
    return chooseImages(chosenApparel);
  }, [apparel]);
  let sliderRef = useRef(null);
  const toggleZoomBtnRef = useRef(null);
  const addFavBtnRef = useRef(null);
  const [slideToShow, setSlideToShow] = useState(3);
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const [buttonText, setButtonText] = useState('Edit Design');
  // setdesignbtn = {text: 'Edit Design'};
  
  useEffect(() => {
    setIsActive(!!generatedImage.photo);
  }, [generatedImage.photo]);

  const handleLike = () => {
    setIsLiked(!isLiked);
    fetchPostLike(!isLiked, generatedImage.img_id, prompt)
      .then(succeeded => {
        if (!succeeded.success) {
          setMessageBannerText(succeeded.message);
          setShowMessageBanner(true);
          setBannerKey(prevKey => prevKey + 1);
          if (succeeded.navigated) {
            navigate('/error-page');
          }
        }
      });
  };

  
  useEffect(() => {
    if (generatedImage.img_id == null) {
      setIsLiked(false);
    } else {
      fetchIsLiked(generatedImage.img_id, setIsLiked, navigate);
    }
  }, [generatedImage.img_id, navigate, setIsLiked]);


  useEffect(() => {
    if (window.innerWidth < 760) {
      setSlideToShow(1);
    }
  }, []);

  const settings = {
    className: "center",
    centerMode: true,
    infinite: apparel !== 'mug',
    centerPadding: "0px",
    slidesToShow: slideToShow,
    speed: 500,
    draggable: !isZoomEnabled,
    touchMove: !isZoomEnabled,
    afterChange: (index) => handleAfterChange(index),
    beforeChange: (current, next) => setSlideIndex(next),
    initialSlide: currentIndex
  };

  const settings_std = {
    className: "center",
    centerMode: true,
    infinite: false,
    centerPadding: "0px",
    slidesToShow: slideToShow,
    speed: 500,
    // draggable: !isZoomEnabled,
    // touchMove: !isZoomEnabled,
    // afterChange: (index) => handleAfterChange(index),
    // beforeChange: (current, next) => setSlideIndex(next),
    initialSlide: 1
  };
  
  
  const {
    setShowMessageBanner,
    setMessageBannerText,
    setBannerKey
  } = useContext(MessageBannerContext);

  useImperativeHandle(ref, () => {
    return {
      async getSavedImageSrc() {
        return await saveAsImage();
      },
      getToggleZoomBtn() {
        return toggleZoomBtnRef.current;
      },
      getSaveBtn() {
        return addFavBtnRef.current;
      },
      disenableZoomer() {
        return setIsZoomEnabled(false);
      },
      resetEditImagePosition() {
        editedImageRef.current.resetImagePosition();
      },
      galleryGoTo(index) {
        setCurrentIndex(index);
        sliderRef.current.slickGoTo(index);
      },
      async getSelectedPreviewImage(apparel, color, patternSrc){
          const images = chooseImages(apparel);
          let index = mapColorToIndex(apparel, color);
          if(index == -1){
            index = 0;
          }
          const clothImg = new Image();
          const patternImg = new Image();
          clothImg.crossOrigin = 'anonymous';
          patternImg.crossOrigin = 'anonymous';
        
          clothImg.src = images[index];
          patternImg.src = patternSrc;
          const outputCanvas = document.createElement('canvas');
          outputCanvas.width = 512;
          outputCanvas.height = 512;
          const outputCanvasCtx = outputCanvas.getContext('2d');
          patternImg.width = 64;
          patternImg.height = 64;
          try {
            await Promise.all([clothImg.decode(), patternImg.decode()]);
            switch (apparel) {
              case "cap":
                outputCanvasCtx.drawImage(patternImg, 192, 156, 128, 128);
                break;
              case "mug":
                outputCanvasCtx.drawImage(patternImg, 163, 163, 204, 204);
                break;
              default:
                outputCanvasCtx.drawImage(patternImg, 192, 118, 128, 128);
                break;
            }
            outputCanvasCtx.drawImage(clothImg, 0, 0, outputCanvas.width, outputCanvas.height);
            return outputCanvas.toDataURL("image/png");
          } catch (error) {
            console.error('error', error);
          }
      }
    };
  }, []);

  function setPreviewImgStyle(apparel){
    if(process.env.REACT_APP_USERTYPE == 'ADMIN'){
    switch (apparel) {
      case 'mug':
        return 'mugPreviewImg';
      case 'cap':
        return 'capPreviewImg';
      default:
        return 'previewImg';
    }
  }
  else {
    switch (apparel) {
      case 'mug':
        return 'mugPreviewImg_std';
      case 'cap':
        return 'capPreviewImg_std';
      default:
        return 'previewImg_std';
    }
  }
  }

  function handleToggleBtnDisable() {
    if (!generatedImage.photo) {
      setShowMessageBanner(true);
      setMessageBannerText("Please generate a design first");
      setBannerKey(prevKey => prevKey + 1);
    }
  }
  const closeZoomerwindow = () => {
    // setToggleActivated(false);
    setButtonText(buttonText === 'Edit Design' ? 'Save Design' : 'Edit Design');

    if (isZoomEnabled) {
      setToggleActivated(false);
      // designbtn = {text : 'Save Design'}
      // saveAsImage();
      // setShowMessageBanner(true);
      // setMessageBannerText("Design is not saved");
    } else {
      setToggleActivated(true);
    }
    setIsZoomEnabled(!isZoomEnabled);
  }
  const toggleZoom = () => {
    setButtonText(buttonText === 'Edit Design' ? 'Save Design' : 'Edit Design');
    if (!generatedImage.photo) {
      handleToggleBtnDisable();
      return
    }
    if (isZoomEnabled) {
      setToggleActivated(false);
      // designbtn = {text : 'Save Design'}
      saveAsImage();
      // setShowMessageBanner(true);
      setMessageBannerText("Design Changed!");
    } else {
      setToggleActivated(true);
    }
    setIsZoomEnabled(!isZoomEnabled);
  };

  function handleAfterChange(index) {
    if (apparel == 'mug')
      return;
    setCurrentIndex(index);
    const localColor = mapIndexToColor(apparel, index);
    setColor(localColor);
    if (localColor !== null) {
      onChange(localColor);
    }
  }

    // upscale image with cloudinary
    const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
    const [error, setError] = useState(null);
  
    const handleImageUpload = async (base64Image) => {
      if (base64Image)  {
        // const uploadedImageUrl = await uploadToCloudinary(base64Image);
        // if (uploadedImageUrl)  {
        //   const enhancedImageUrl = enhanceImage(uploadedImageUrl);
        //   setUploadedImageUrl(enhancedImageUrl);
        //   setToggled(enhancedImageUrl);
        //   setEditedImage(enhancedImageUrl);
        // }
        const enhancedImage = await enhanceImageClarity(base64Image);
        if(enhancedImage){
          setUploadedImageUrl(enhancedImage);
          setToggled(enhancedImage);
          setEditedImage(enhancedImage);
        }
      } else {
        setError('Please enter a valid base64 image URL.');
      }
    };
    // const uploadToCloudinary = async (base64Image) => {
    //   try {
    //     const response = await axios.post(
    //       `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
    //       {
    //         file: base64Image,
    //         upload_preset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
    //       },
    //       {
    //         headers: { 'Content-Type': 'application/json' },
    //       }
    //     );
    //     console.log(response.data); 
    //     return response.data.secure_url;
    //   } catch (error) {
    //     console.error('Error uploading image to Cloudinary:', error.response ? error.response.data : error.message); // Log the error response data
    //     setError(error.response ? error.response.data.error.message : error.message); // Set error message
    //     return null;
    //   }
    // };
  
    // const enhanceImage = (url) => {
    //   // Apply image enhancement transformations
    //   const enhancedUrl = `${url.replace('/upload/', '/upload/e_gen_restore/')}`;
    //   return enhancedUrl;
    // };
    
  
  async function saveAsImage() {
    const imageRef = editedImageRef.current.getEditedImageRef();
    const transformedCanvas = document.createElement('canvas');
    transformedCanvas.width = 512;
    transformedCanvas.height = 512;
    // transformedCanvas.width = 1080;
    // transformedCanvas.height = 1080;
    const ctx = transformedCanvas.getContext('2d');
    const image = new Image();
    let tmp = imageRef.current.innerHTML;
    imageRef.current.innerHTML = '';
    const canvas = await html2canvas(imageRef.current, {
      allowTaint : false,
      useCORS: true,
      scale: 4
    });
    image.src = canvas.toDataURL("image/jpeg");
    await Promise.all([image.decode()]);
    ctx.drawImage(image, 0, 0, transformedCanvas.width, transformedCanvas.height);
    imageRef.current.innerHTML = tmp;
    let imgSrc = transformedCanvas.toDataURL("image/jpeg");
    handleImageUpload(imgSrc);
  }

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const handleThumbnailClick = (index) => {
      setCurrentImageIndex(index);
    };
  return (
    <div id="product-gallery" className={`sliderContainer overflow-hidden ${!isZoomEnabled ? '' : 'zoomer'}`} ref={ref}>
      {/* for alumni modal */}
      <div className={`${isZoomEnabled ? 'hidden' : ''}  ${!(process.env.REACT_APP_USERTYPE == 'STUDENT') ? `` : `hidden`}`}>
        <Slider
          ref={sliderRef}
          className={`${isZoomEnabled ? 'hidden' : ''}`}
          {...settings}>
          {imageList.map((image, index) => (         
            <div key={index} className="relative grid justify-items-center items-center h-[26rem] items-center h-[32rem] md:h-72 lg:h-96 w-96" >
              <img id={index} draggable="false" className={`absolute z-[-1] ${apparel=='hoodie' ? 'Hoodie' : ''} ${apparel=='tshirt' ? 'Tshirt' : ''} ${setPreviewImgStyle(apparel)}`} src={editedImage} alt="" />
              <img draggable="false" src={image} alt="" className="object-contain mx-auto h-[32rem] md:h-72 lg:h-96 z-30" />
            </div>
          ))         
          }
        </Slider>
      </div>
      {/* alumni modal end */}
      {/* for student modal */}
    <div className={`${isZoomEnabled ? 'hidden' : ''} ${!(process.env.REACT_APP_USERTYPE === 'STUDENT') ? 'hidden' : 'flex'}`} style={{ justifyContent: 'center'}}>
      <div key="1" style={{ height: '429.55px', display: 'flex' }} className="relative grid justify-items-center items-center h-[26rem] md:h-72 lg:h-96 w-96">
        <img id="1" draggable="false" className={`absolute z-[-1] ${apparel === 'hoodie' ? 'Hoodie' : ''} ${apparel === 'tshirt' ? 'Tshirt' : ''} ${setPreviewImgStyle(apparel)}`} src={editedImage} alt="" />
        <img draggable="false" src={imageList[currentImageIndex]?.product_src} alt="" className="object-contain mx-auto h-[32rem] md:h-72 lg:h-96 z-30" />
      </div>
    </div>
    <div className={`${isZoomEnabled ? 'hidden' : ''} row flex ${!(process.env.REACT_APP_USERTYPE === 'STUDENT') ? 'hidden' : 'flex'}`} style={{ width: '100%', justifyContent: 'center' }}>
        {imageList.map((image, index) => (
          <div
            key={index}
            className={`z-40 thumbnail ${currentImageIndex === index ? 'thumbnail-selected' : ''}`}
            onClick={() => handleThumbnailClick(index)}
          >
            <img src={image.product_src} alt={`Thumbnail ${index + 1}`} />
          </div>
        ))}
      </div>
      {/* student modal end */}
      <ZoomItem
        isZoomEnabled={isZoomEnabled}
        imageSrc={generatedImage.photo}
        TshirtImageSrc={imageList[currentImageIndex]?.product_src}
        mockImageSrc={imageList[currentImageIndex]?.mock_src}
        closeAction={closeZoomerwindow}
        ref={editedImageRef} />
      <ProductGalleryFooter apparel={apparel} currentIndex={currentIndex} onFooterClick={(newColorIndex) => sliderRef.current.slickGoTo(newColorIndex)} />
      <div className={`Save-btn-contaiiner ${!isZoomEnabled ? 'hidden' : ''}`}>
        <button  ref={toggleZoomBtnRef} onClick={toggleZoom} 
            style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`, backgroundColor: `${process.env.REACT_APP_THEME_BG_COLOR}`}}
            className="text-zinc-100 font-extrabold rounded-xl text-xl inline-block w-4/12 md:w-2/12 lg:w-2/12 save-btn">
            Save Design</button>
      </div>
      <div className={`grid grid-cols-12 mt-4 ${!isZoomEnabled ? '' : 'hidden'}`}>
        <div className="col-span-12">
          <button ref={toggleZoomBtnRef} onClick={toggleZoom} 
            style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`, backgroundColor: `${process.env.REACT_APP_THEME_BG_COLOR}`}}
            className="text-zinc-100 font-extrabold py-2 px-4 rounded-xl text-xl inline-block w-4/12 md:w-2/12 lg:w-2/12">
            Edit Design
          </button>
        </div>
      </div>

    </div>
  );
});

export default ProductGallery;

