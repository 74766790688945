import FileSaver from 'file-saver';
import { randomPrompts } from '../data';
import { v4 as uuidv4 } from 'uuid';

  export const getRandomPrompt = () => {
    const randomIndex = Math.floor(Math.random() * randomPrompts.length);
    const randomPrompt = randomPrompts[randomIndex];

    return randomPrompt;
  };

  export async function downloadImage(photo) {
    const _id = uuidv4();
    FileSaver.saveAs(photo, `download-${_id}.jpg`);
  }

  export const getSessionID = () => {
    let sessionID = sessionStorage.getItem('sessionID');
    if (!sessionID) {
      sessionID = uuidv4(); 
      sessionStorage.setItem('sessionID', sessionID);
    }
    return sessionID;
  };
  
  export async function handleCheckout(obj_data) {
      try {
      const {apparel, color, size, email, sessionID} = obj_data;
        const response = await fetch(process.env.REACT_APP_SERVER_NEW + '/create-checkout-session', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ apparel: apparel, color: color, size: size, email: email, sessionID: sessionID }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        if (data.url) {
          window.location.href = data.url;
      } else {
          throw new Error('Stripe session URL not received');
      }
      } catch (error) {
        console.error('Failed to create checkout session', error);
        alert('Failed to create checkout session: ' + error.message);
      }
    };

  const imageMap = {};
  const _imageMap = JSON.parse(process.env.REACT_APP_PRODUCTS_IMAGEMAPPING ? process.env.REACT_APP_PRODUCTS_IMAGEMAPPING : {})


  for (const i in _imageMap) {
    imageMap[i] = {};
    for (const j in _imageMap[i]) {
      imageMap[i][j] = {
        product_src: require(`./../${_imageMap[i][j].product_src}`),
        mock_src: require(`./../${_imageMap[i][j].mock_src}`)
      };
    }
  }

  const colorMappings = JSON.parse(process.env.REACT_APP_PRODUCTS_COLORMAPPINGS ? process.env.REACT_APP_PRODUCTS_COLORMAPPINGS : {})
  const colorMappingCSS = JSON.parse(process.env.REACT_APP_PRODUCTS_COLORMAPPING_CSS ? process.env.REACT_APP_PRODUCTS_COLORMAPPING_CSS : {})

  export function mapIndexToColorCSS(apparel) {
    return colorMappingCSS[apparel] ? colorMappingCSS[apparel]: null;
  }

  export function mapIndexToColor(apparel, index) {
    return colorMappings[apparel] ? colorMappings[apparel][index] : null;
  }

  export function mapColorToIndex(apparel, color) {
    const colors = colorMappings[apparel];
    if (!colors) return null;
    return colors.indexOf(color);
  }

  // export const chooseImages = (apparel) => {
  //   const apparelImages = imageMap[apparel];
  //   if (typeof apparelImages !== 'object' || apparelImages == null) {
  //     return [];
  //   }

  //   const imagePaths = Object.entries(apparelImages).reduce((acc, [key, value]) => {
  //     if (key !== 'default') {
  //       acc.push(value);
  //     }
  //     return acc;
  //   }, []);
  
  //   return imagePaths;
  // };
  
  export const chooseImages = (apparel) => {
    const apparelImages = imageMap[apparel];
    if (typeof apparelImages !== 'object' || apparelImages == null) {
      return [];
    }
  
    const imagePaths = Object.entries(apparelImages).reduce((acc, [key, value]) => {
      if (key !== 'default') {
        acc.push({
          product_src: value.product_src,
          mock_src: value.mock_src
        });
      }
      return acc;
    }, []);
  
    return imagePaths;
  };

  

  export const chooseImage = (apparel, color) => {
	const apparelImages = imageMap[apparel];
	return apparelImages && apparelImages[color] ? apparelImages[color] : apparelImages ? apparelImages.default : null;
  };

  export const loadState = (key, defaultValue, storageName) => {
	try {
	  const savedState = sessionStorage.getItem(storageName);
	  if (savedState) {
		const state = JSON.parse(savedState);
		return state[key] !== undefined ? state[key] : defaultValue;
	  } else {
		return defaultValue;
	  }
	} catch (err) {
	  console.error('Error reading from sessionStorage:', err);
	  return defaultValue;
	}
  };

  export const handleFailure = (error) => {
    console.error("Google login failed:", error);
  };

 export function splitName(fullName) {

   fullName = fullName.trim();

   if (!fullName.includes(" ")) {
     return { firstName: fullName, lastName: "" };
   }
   const parts = fullName.split(" ");
   const prefixesAndSuffixes = ["Jr", "Sr", "II", "III", "IV", "V", "Dr", "Mr", "Mrs", "Miss", "Ms"];
   const lastNameParts = parts.slice(-1);
   if (parts.length > 2 && prefixesAndSuffixes.includes(parts.at(-2).replace(".", ""))) {
     lastNameParts.unshift(parts.slice(-2, -1)[0]);
   }
   if (parts.length > 2 && parts.at(-2).includes("-")) {
     lastNameParts.unshift(parts.slice(-2, -1)[0]);
   }
 
   // Assume the first part is the firstName and the rest is lastName
   const firstName = parts.slice(0, parts.length - lastNameParts.length).join(" ");
   const lastName = lastNameParts.join(" ");
 
   return { firstName, lastName };
  };