import React, { useContext, useState, useEffect } from "react";
import "./LandingPage.css";
import ClassButton from "../components/ClassButton";
import StepCards from "./StepCards";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useUser } from "../context/UserContext";
import ProductCardSlider from "./ProductCardSlider";
import RShowcase from "./RShowcase";
import { PricesContext } from '../context/PricesContext';

const ImageChangeInterval = 3000;


function GetBackendImage() {
  const imageNumber = 12;
  let imageList = [];
  for (let i = 1; i <= imageNumber; i++) {
    imageList.push(`${process.env.REACT_APP_SERVER_NEW}/image/p${i}.png`);
  }
  return imageList;
}

const LandingPage = () => {
  const { user } = useUser();
  const items = process.env.REACT_APP_PRODUCTS ? JSON.parse(process.env.REACT_APP_PRODUCTS) : []
  const itemsDesc = process.env.REACT_APP_PRODUCTS_DESCRIPTION ? JSON.parse(process.env.REACT_APP_PRODUCTS_DESCRIPTION) : []
  const itemsImglist = process.env.REACT_APP_PRODUCT_IMAGELIST ? JSON.parse(process.env.REACT_APP_PRODUCT_IMAGELIST) : []
  const itemsColorlist = process.env.REACT_APP_PRODUCT_IMAGECOLOR ? JSON.parse(process.env.REACT_APP_PRODUCT_IMAGECOLOR) : []
  const {priceMap, getPriceNum} = useContext(PricesContext);

  const products = [];
  if(items && items && itemsDesc && (items.length == itemsDesc.length && 
    items.length == itemsImglist.length && itemsDesc.length == itemsImglist.length))
  {
    for(var i=0; i<items.length; i++)
    {
      let item_img = require(`../${itemsImglist[i]}`)
      var obj = {
        type: items[i],
        name: `${process.env.REACT_APP_COLLEGE_NAME} AI Designed ${itemsDesc[i]}`,
        price: getPriceNum(items[i]),
        color: (itemsColorlist && itemsColorlist[i]) ? itemsColorlist[i] : 'white',
        imageList: [item_img]
      }
      products.push(obj);
    }
  }
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 500) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  let className = 'top';
  if (scrolled) {
    if (window.innerWidth <= 544){
    className += ' scrolled';
    }
  }

  return (
    <div
      className="justify-center bg-slate-200 text-black"
    >
      <div className="flex flex-col items-center w-screen">
        <div className="grid justify-center place-items-start grid-cols-1 md:grid-cols-2">
          <div
            style={{
              position: "sticky",
              width: "100%",
              textAlign: "center",
              paddingRight: "5%",
              paddingLeft: "5%",
            }}

            className={`${className} top-[8%] md:top-[8%] flex flex-col items-center`}
          >
            {
              GetBackendImage().map((image, index) => {
                return (
                  <div key={index} className="hidden">
                    <img src={image} alt="cache" />
                  </div>
                );
              })
            }

            <RShowcase imageList={GetBackendImage()} changeInterval={3000} />
            <div className="h-4"></div>
            <div style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}} className="text-black text-5xl text-center">
              Personalized <br /> {process.env.REACT_APP_COLLEGE_NAME} <br /> Drops
            </div>

            <ClassButton
              text="Design Now"
              className={`font-bold tracking-wide text-gray-200 rounded-[20px] mt-12 px-3 py-1 h-fit md:text-3xl md:px-8 md:py-2 ${(window.innerWidth <= 544) ? `btn-mbl`: ``}`}
              link={user && user.isLoggedIn ? "/product" : "/auth"}
            />

          </div>

          <div className="mx-5 my-32">
            <StepCards />
          </div>
        </div>

        <div className="w-full h-10" />

        <div id="landing-product-slider" className={`w-[96vw] ${!(process.env.REACT_APP_USERTYPE == 'STUDENT') ? `` : `hidden`}`}>{/*w-screen*/}
          <ProductCardSlider products={products} changeInterval={ImageChangeInterval} />
        </div>

        <div className="w-full h-10" />

      </div>
    </div>
  );
};

export default LandingPage;
