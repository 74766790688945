import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import InformationPage from './InformationPage/InformationPage';
import Legal from './components/Legal'
import { AppProvider } from './context/AppContext';
import { ImageProvider } from './context/ImageContext';
import { PricesProvider } from './context/PricesContext';
import { MessageBannerProvider } from "./context/MessageBannerContext";
import ThankYouPage from './ThankYouPage/ThankYouPage';
import ErrorPage from './ErrorPage/ErrorPage';
import LandingPage from './LandingPage/LandingPage';
import ProductPage from './ProductPage/ProductPage';
import { UserProvider } from './context/UserContext';
import SAMLResponseHandler from './AuthPage/SAMLResponseHandler';
import AuthPage from './AuthPage/AuthPage';
import FavoritePage from './FavoritePage/FavoritePage';
import ProductInformation from './InformationPage/ProductInformation/ProductInformation';
import PromptInformation from './InformationPage/PromptInformation/PromptInformation';
import NavBar from './components/NavBar';
import UserPage from './UserPage/UserPage';
import CartPage from './CartPage/CartPage';
import DriverPage from './DriverPage/DriverPage';
import { Navigate } from 'react-router-dom';
import { useUser } from "./context/UserContext"; 
import ContactPage from './ContactPage/ContactPage';
import Loader from './components/loader'
import Demo from './ProductPage/Demo'



const App = () => {
	const [loading, setLoading] = useState(true);

useEffect(() => {
  const timeout = setTimeout(() => {
		setLoading(false);
  }, 500); 
  return () => clearTimeout(timeout);
}, []);
	return (
		<div>
		{loading ? (
			<Loader />
		  ) : (
		<AppProvider>
			<ImageProvider>
				<UserProvider>
					<MessageBannerProvider>
						<PricesProvider>
						<SAMLResponseHandler/>
		<div className='flexcenter relative'>
			<NavBar />
			<Routes>
			<Route path="/" element={<Demo />} />
			<Route path="/auth" element={<AuthPage />} />
			</Routes>
			<Legal />
		</div>
						</PricesProvider>
					</MessageBannerProvider>
				</UserProvider>
			</ImageProvider>
		</AppProvider>
		  )}
		</div>
	  );
};
export default App;