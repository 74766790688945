import React, { forwardRef } from 'react';
import './PromptBoxButton.css';

const PromptBoxButton = forwardRef(({ text, onClick, className, loading, disabled, onMouseOver}, ref) => (
    <button
      ref={ref}
      style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`, width: (window.innerWidth <= 544)? '100%': ''}}
      className={`bg-teal-500 text-zinc-100 font-extrabold py-2 px-4 rounded-xl whitespace-nowrap ${className}`}
      onClick={onClick}
      disabled={loading || disabled}
      onMouseOver={onMouseOver}
    >
      {loading ? <div className="spinner"></div> : text}
    </button>
  ));


export default PromptBoxButton;
