import React from "react";
import { useNavigate } from "react-router-dom";

const ClassButton = ({ text, link, className, onClick }) => {
    const navigate = useNavigate();

    function handleButtonClick() {
        if (!onClick) {
            navigate(link);
        } else {
            onClick();
        }
    }

    return (
        <button style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`, backgroundColor: `${process.env.REACT_APP_THEME_BG_COLOR}`}} className={className} onClick={handleButtonClick}>
            {text}
        </button>
    );
};

export default ClassButton;
