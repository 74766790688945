import { React, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from '../context/AppContext';

export default function ProductSection({prop}) {
  function MerchandiseButton({ title, description, image, onClick }) {
    return (
      <div className="p-2 w-1/2 max-w-[45%] m-2 sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 rounded-[12px] shadow-[0_0px_10px_3px_rgba(0,0,0,0.3)] hover:shadow-[0_0px_5px_2px_rgba(0,0,0,0.4)]">
        <img src={image} alt={description} onClick={onClick} />
        <div className="font-bold">{title}</div>
      </div>
    );
  }


  function ProductSectionContainer() {
    
    const navigate = useNavigate();
    const { apparel, setApparel, setColor } = useContext(AppContext);

    const items = process.env.REACT_APP_PRODUCTS ? JSON.parse(process.env.REACT_APP_PRODUCTS) : []
    const itemsDesc = process.env.REACT_APP_PRODUCTS_DESCRIPTION ? JSON.parse(process.env.REACT_APP_PRODUCTS_DESCRIPTION) : []
    const itemsImglist = process.env.REACT_APP_PRODUCT_NAV_IMAGELIST ? JSON.parse(process.env.REACT_APP_PRODUCT_NAV_IMAGELIST) : process.env.REACT_APP_PRODUCT_IMAGELIST ? JSON.parse(process.env.REACT_APP_PRODUCT_IMAGELIST) : []
    const itemsColorlist = process.env.REACT_APP_PRODUCT_NAV_IMAGECOLOR ? JSON.parse(process.env.REACT_APP_PRODUCT_NAV_IMAGECOLOR) : []
    
    const products = [];
    if(items && items && itemsDesc && (items.length == itemsDesc.length && 
      items.length == itemsImglist.length && itemsDesc.length == itemsImglist.length))
    {
      for(var i=0; i<items.length; i++)
      {
        let item_img = require(`../${itemsImglist[i]}`)
        var obj = {
          id : i,
          type: items[i],
          title: itemsDesc[i],
          color: (itemsColorlist && itemsColorlist[i]) ? itemsColorlist[i] : 'white',
          image: item_img
        }
        products.push(obj);
      }
    }

    return (
      <div className={`flex flex-wrap justify-start -mx-2`}>
        {products.map((item) => (
          <MerchandiseButton
            key={item.id}
            title={item.title}
            image={item.image}
            onClick={() => {
              // navigate(0)
              // ProductSection.prop(false)
              setApparel(item.type);
              setColor(item.color);
              navigate("/product");
            }}
          />
        ))}
      </div>
    );
  }

  return (
    <div className={`rounded-[12px]  ${!(process.env.REACT_APP_USERTYPE == 'STUDENT') ? `` : `hidden`}`}>
      <div className="flex flex-row justify-start text-xl text-gray-bold mb-2">
        Products
      </div>

      <div className="h-2" />

      <ProductSectionContainer />
    </div>
  );
}
