import "./InformationTopNav.css"
function InformationTopNav({title}){
    return (
        <div className="w-full">
            <span className="w-[80%] inline-block text-left text-2xl align-middle" style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>{title}</span>
            <span className="material-symbols-outlined w-[20%] inline-block text-right align-middle cursor-pointer" onClick={()=> {window.history.back()}}>close</span>
        </div>
    )
}

export default InformationTopNav