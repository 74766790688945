import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import DrophouseLogo from "../assets/logo_footer_t.png";
import { useUser } from "../context/UserContext";
import { AppContext } from "../context/AppContext";
export default function NavBar() {
  const navigate = useNavigate();

  return (
    <div className={`w-full z-[50] fixed`}>
      <div
        className={`flex flex-row p-2 ${(window.innerWidth >= 544) ? `h-[2rem]`: `h-[3rem]`} md:h-[3rem] items-center justify-center bg-gray-200`}
      >
        <div className="basis-1/2 text-center">
          <img
            className={`${(window.innerWidth >= 544) ? `h-[2rem]`: `h-[3rem]`} md:h-[3rem] mx-auto`}
            src={DrophouseLogo}
            alt="logo"
            style={{maxWidth : `${(window.innerWidth >= 544) ? `` : `max-content`}`}}
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
      </div>
    </div>
  );
}
