import React, { useState, useEffect } from 'react';
import "./InformationPage.css";
import { useContext } from 'react';
import { MessageBannerContext } from "../context/MessageBannerContext";
import MessageBanner from "../components/MessageBanner";
import { useNavigate } from 'react-router';
import { fetchShippingInfo, fetchUpdateShippingInfo } from '../utils/fetch';
import { USStatesNames } from '../utils/USStateNames';
import { Modal, Input, Select } from "antd";
import { FormOutlined } from "@ant-design/icons";
import SelectableCard from '../components/SelectableCard';
import { useLocation } from 'react-router-dom';
import { createCheckoutSession } from '../utils/fetch';
import ClassInput from '../components/ClassInput';
const emailRegex = /\S+@\S+\.\S+/;

const InformationPage = () => {
  const location = useLocation();
  const { productInfo } = location.state || {};
  
  const initialFormData = () => {
    const savedFormData = sessionStorage.getItem('formData');
    return savedFormData ? JSON.parse(savedFormData) : {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      streetAddress: '',
      streetAddress2: '',
      city: '',
      stateProvince: '',
      postalZipcode: '',
    };
  };
  const [isFormValid, setIsFormValid] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [shippingInfo, setShippingInfo] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [selectedId, setSelectedId] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalAddressType, setModalAddressType] = useState("");
  const [modalData, setModalData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
  });

  const {
    showMessageBanner,
    setShowMessageBanner,
    messageBannerText,
    setMessageBannerText,
    bannerKey,
    setBannerKey
  } = useContext(MessageBannerContext);

  useEffect(() => {
    prefillModal('primary')
  }, [shippingInfo])

  useEffect(() => {
    setTermsAccepted(false);
    const isValidEmail = emailRegex.test(formData.email);
    const areAllFieldsFilled = Object.entries(formData).every(([key, value]) =>
      key === 'streetAddress2' || value.trim() !== '' // Exclude 'streetAddress2' from mandatory fields
    );
    setIsFormValid(isValidEmail && areAllFieldsFilled);
    fetchShippingInfo(setShippingInfo, navigate)
    .then(result => {
      if(result.shipping_info.length === 0){
        setModalAddressType("primary")
        prefillModal("primary")
        setIsModalOpen(true)
      }
    })
  }, [updateTrigger]);

  const forceUpdate = () => {
    setUpdateTrigger(prev => !prev); 
  };

  function findAddress(type) {
    let shipping_info = shippingInfo.shipping_info
    if (!shipping_info)
      return '';
    for (let i = 0; i < shipping_info.length; i++) {
      if (shipping_info[i].addressType === type) {
        return shipping_info[i];
      }
    }
    return '';
  }

  const renderShippingInfoContent = (shippingInfo) => {
    if (!shippingInfo || shippingInfo === '') {
      return <p className="text-lg" style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>Not Applicable</p>;
    } else {
      return (
        <div>
          <br />
          <p className="text-lg" style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>{shippingInfo.firstName} {shippingInfo.lastName}</p>
          <p className="text-lg" style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>{shippingInfo.streetAddress} {shippingInfo.streetAddress2}, {shippingInfo.city}, {shippingInfo.stateProvince}, {shippingInfo.postalZipcode}</p>
          <p className="text-lg" style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>{shippingInfo.email}</p>
          <p className="text-lg" style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>{shippingInfo.phone}</p>
        </div>
      );
    }
  };

  const handleCardClick = (index) => {
    setSelectedId(prev => (prev === index ? null : index));
  };

  const handleAddressItemEdit = () => {
    const { firstName, lastName, email, phone, address1, address2, city, state, zipCode } = modalData;
    if (!firstName || !lastName || !email || !phone || !address1 || !city || !state || !zipCode) {
      setMessageBannerText('Please fill in all required fields.');
      setShowMessageBanner(true);
      setBannerKey(prevKey => prevKey + 1);
      return;
    } else if (!emailRegex.test(email)) {
      setMessageBannerText('Please fill in a valid email.');
      setShowMessageBanner(true);
      setBannerKey(prevKey => prevKey + 1);
      return;
    }

    const new_shipping_info = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      streetAddress: address1,
      streetAddress2: address2,
      city: city,
      stateProvince: state,
      postalZipcode: zipCode,
      addressType: 'primary'
    }

    fetchUpdateShippingInfo(new_shipping_info, navigate)
      .then(data => {
        setTimeout(()=>{
          forceUpdate()
        }, 1000)
      })
    resetModal();
    setIsModalOpen(false);
  }
  const handleCancel = () => {
    resetModal();
    setIsModalOpen(false);
  }

  const resetModal = () => {
    setModalData({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipCode: ''
    });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading to true when checkout starts

    if (!termsAccepted || !selectedId) {

      let message = '';

      if (!termsAccepted) {
        message = 'Please accept the terms and conditions.';
      } else if (!selectedId) {
        message = 'Please pick a shipping address.';
      } else {
        message = 'The selected shipping address is not applicable.'
      }
      setMessageBannerText(message);
      setShowMessageBanner(true);
      setBannerKey(prevKey => prevKey + 1);
      setIsLoading(false);
      return;
    }
    try {
      productInfo["shipping_info"] = findAddress('primary');
      createCheckoutSession(productInfo, navigate); // await? 
    } catch (error) {
      console.error('Checkout error:', error);
      setIsLoading(false); 
    }
  };

  const prefillModal = (addressType) => {
    const currentAddress = findAddress(addressType);
    if (currentAddress) {
      setModalData({
        firstName: currentAddress.firstName,
        lastName: currentAddress.lastName,
        email: currentAddress.email,
        phone: currentAddress.phone,
        address1: currentAddress.streetAddress,
        address2: currentAddress.streetAddress2,
        city: currentAddress.city,
        state: currentAddress.stateProvince,
        zipCode: currentAddress.postalZipcode
      });
    }
  };
  
  const handleModalInputChange = (field, value) => {
    setModalData(prev => ({ ...prev, [field]: value }));
  };

  return (
    <div>
      {showMessageBanner && <MessageBanner message={messageBannerText} keyTrigger={bannerKey} />}
      <Modal
        title={<p className="text-lg" style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>{`Edit Address`}</p>}
        open={isModalOpen}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        closeIcon={false}
        zIndex={40}
        width={'80%'}
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
        {['firstName', 'lastName', 'email', 'phone', 'address1', 'address2', 'city', 'zipCode'].map(field => (
            <div key={field}>
              <h2 className='text-start'>{field.replace(/([A-Z])/g, ' $1').toUpperCase()}<span className="text-red-600 ml-2">*</span></h2>
              <ClassInput
                id={`modal${field}`}
                placeholder={field.replace(/([A-Z])/g, ' $1')}
                value={modalData[field]}
                onChange={e => handleModalInputChange(field, e)}
              />
            </div>
          ))}
          <div>
            <h2 className='text-start' style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>STATE<span className="text-red-600 ml-2">*</span></h2>
            <Select id="modalState"
              placeholder="SELECT STATE"
              value={modalData.state}
              style={{ width: "100%", height: "40px", marginBottom: "10px", padding:"0px", borderColor:"lightgrey"
              ,borderWidth:"2px", boxShadow:"none", borderRadius:'0px'}}
              onChange={(value) => { handleModalInputChange('state', value)}}
              options={USStatesNames} />
          </div>
        </div>
        
          <br />
          <div className="flex flex-row w-full justify-end">
            <button className="bg-gray-200 text-black-100 font-extrabold py-2 px-4 rounded-full mr-5" onClick={handleCancel}>CANCEL</button>
            <button
              style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`, backgroundColor: `${process.env.REACT_APP_THEME_BG_COLOR}`}}
              className="bg-sky-600 text-zinc-100 font-extrabold py-2 px-4 rounded-full text-lg"
              onClick={handleAddressItemEdit}
            >
              OK
            </button>
          </div>
      </Modal>


      <form className="infoForm" onSubmit={handleSubmit}>
        <div className="apparel-text font-bold text-lg" style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>Provide your information to continue</div>
        <SelectableCard index={1} onClick={handleCardClick} selected={selectedId === 1}>
          <h2 className="text-lg" style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>Address</h2>
          {renderShippingInfoContent(findAddress("primary"))}
          <FormOutlined
            onClick={(e) => {
              e.stopPropagation();
              setModalAddressType("primary")
              prefillModal("primary")
              setIsModalOpen(true)
            }}
          />
        </SelectableCard>
        <div className="termsCheckbox">
          <input
            type="checkbox"
            checked={termsAccepted}
            onChange={e => setTermsAccepted(e.target.checked)}
          />
          <label style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>
            I agree to the <a href="/Terms.pdf" target="_blank" style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>Terms and Conditions</a>
          </label>
        </div>

        <button className="continueBtn" type="submit" disabled={isLoading} style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`, color: `${process.env.REACT_APP_THEME_BG_COLOR}`}}>
          {isLoading ? (
            <div className="snippet" data-title="dot-elastic">
          <div className="stage">
            <div className="dot-elastic"></div>
          </div>
        </div>
          ) : (
            `${!(process.env.REACT_APP_USERTYPE == 'STUDENT') ? `CHECKOUT` : `ORDER`}`
          )}
        </button>
      </form>
    </div>
  );
}
export default InformationPage;