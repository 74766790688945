import React, { createContext, useContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import app from './../firebase-config';
import { splitName } from '../utils';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

const defaultUser = {
  isLoggedIn: true,
  firstName: 'Demo',
  lastName: 'User',
  email: 'demo@example.com',
  phoneNumber: '',
};

export const UserProvider = ({ children }) => {
  const auth = getAuth(app);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, currentUser => {
      // if (currentUser) {
      //   const { displayName, email, phoneNumber } = currentUser;
      //   let firstName = '';
      //   let lastName = '';
        
      //   if (displayName) {
      //     const names = splitName(displayName);
      //     firstName = names.firstName;
      //     lastName = names.lastName;
      //   } else if (email) {
      //     firstName = email.split('@')[0];
      //   }

      //   setUser({
      //     isLoggedIn: true,
      //     firstName,
      //     lastName,
      //     email,
      //     phoneNumber,
      //   });
      // } else {
        setUser(defaultUser); // Set default user when not logged in
      // }
    });

    return () => unsubscribe();
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setUser(defaultUser); // Set default user after sign out
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <UserContext.Provider value={{ user, handleSignOut }}>
      {children}
    </UserContext.Provider>
  );
};


