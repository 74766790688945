import { React } from "react";
import { mapIndexToColorCSS } from '../utils';
export default function ProductColorFooter({ currentIndex, onFooterClick , apparel}) {    
    const ColorBall = ({ color, highlight, onClick }) => {
        return (
            <div
                style={{
                    backgroundColor: color,
                    border: "1px solid grey",
                    borderRadius: "50%",
                    width: highlight ? "25px" : "18px",
                    height: highlight ? "25px" : "18px",
                }}
                onClick={onClick}
            ></div>
        );
    };

    return (
        <div className={`flex flex-row justify-center items-center space-x-5 ${!(process.env.REACT_APP_USERTYPE == 'STUDENT') ? `` : `hidden`}`}>
            {
                mapIndexToColorCSS(apparel).map((color, index)=>{
                    return <ColorBall key={index} color={color} highlight={currentIndex === index} onClick={() => { onFooterClick(index);}}/>
                })
            }
        </div>
    );
}