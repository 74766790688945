import { React, useEffect, useState, useContext } from "react";
import "./UserPage.css";
import { Modal, Input, Select } from "antd";
import { FormOutlined, LeftCircleOutlined } from "@ant-design/icons";
import { USStatesNames } from "../utils/USStateNames";
import { fetchBasicUserInfo, fetchOrderHistory, fetchShippingInfo, fetchUpdateBasicInfo, fetchUpdateShippingInfo } from "../utils/fetch";
import { useNavigate } from 'react-router';
import { PricesContext } from '../context/PricesContext';
import LazyLoad from 'react-lazyload';

export default function UserPage() {
  const [isNormalModalOpen, setIsNormalModalOpen] = useState(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [modalItem, setModalItem] = useState("");
  const [shippingInfo, setShippingInfo] = useState([]);
  const [basicUserInfo, setBasicUserInfo] = useState(null);
  const [orderList, setOrderList] = useState([]);
  const [modalAddressType, setModalAddressType] = useState("");
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const {priceMap, getPriceNum} = useContext(PricesContext);
  const [baseModalData, setBaseModalData] = useState({
      firstName: '',
      lastName: '',
      email: '',
      phone: ''
  });
  const [modalData, setModalData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
  });

  const navigate = useNavigate();
  useEffect(() => {
    prefillModal('primary')
  }, [shippingInfo])
  useEffect(() => {
    setBaseModalData(basicUserInfo)
  }, [basicUserInfo])
  useEffect(() => {
    fetchShippingInfo(setShippingInfo, navigate);
    fetchBasicUserInfo(navigate)
      .then(data => {
        setBasicUserInfo(data);
      });
    fetchOrderHistory(navigate)
      .then(orders => {
        let transformedData = [];
        if (orders != null) {
          orders.order_history.forEach(order => {
            order.item.forEach(item => {
              transformedData.push({
                imageSrc: item.thumbnail,
                title: `Customized ${process.env.REACT_APP_COLLEGE_NAME} ${item.color} ${item.apparel}`,
                type: item.apparel,
                color: item.color,
                size: item.size,
                price: getPriceNum(item.apparel.toLowerCase()),
                shippingStatus: order.status
              });
            });
          });
        }
        setOrderList(transformedData);
      });
  }, [priceMap, updateTrigger]);

  function findAddress(type) {
    let shipping_info = shippingInfo.shipping_info
    if (!shipping_info)
      return '';
    for (let i = 0; i < shipping_info.length; i++) {
      if (shipping_info[i].addressType === type) {
        return shipping_info[i];
      }
    }
    return '';
  }
  const prefillModal = (addressType) => {
    const currentAddress = findAddress(addressType);
    if (currentAddress) {
      setModalData({
        firstName: currentAddress.firstName,
        lastName: currentAddress.lastName,
        email: currentAddress.email,
        phone: currentAddress.phone,
        address1: currentAddress.streetAddress,
        address2: currentAddress.streetAddress2,
        city: currentAddress.city,
        state: currentAddress.stateProvince,
        zipCode: currentAddress.postalZipcode
      });
    }
  };
  const refetchShippingInfo = () => {
    setUpdateTrigger(prev => !prev);  
  };

  function findAddress(type) {
    let shipping_info = shippingInfo.shipping_info
    if (!shipping_info)
      return '';
    for (let i = 0; i < shipping_info.length; i++) {
      if (shipping_info[i].addressType === type) {
        return shipping_info[i];
      }
    }
    return '';
  }

  function addressToString(shippingInfo, num) {
    if (shippingInfo === '') {
      if (num === 1) {
        return "No first Address"
      } else {
        return "No secondary Address"
      }
    } else {
      return `${shippingInfo.streetAddress} ${shippingInfo.streetAddress2}, ${shippingInfo.city}, ${shippingInfo.stateProvince}, ${shippingInfo.postalZipcode}`;
    }
  }

  function getName() {
    if (!basicUserInfo)
      return '';
    else
      return basicUserInfo.firstName + ' ' + basicUserInfo.lastName
  }

  function getEmail() {
    if (!basicUserInfo)
      return '';
    else
      return basicUserInfo.email
  }

  function getPhone() {
    if (!basicUserInfo)
      return '';
    else
      return basicUserInfo.phone
  }

  function closeModal() {
    resetModal()
    setIsAddressModalOpen(false)
    setIsNormalModalOpen(false)
  }
  const resetModal = () => {
    setModalData({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipCode: ''
    });
    setBaseModalData({
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    })
  };
  function EditBtn({ item, isAddress, addressType }) {
    const showModal = () => {
      if (isAddress) {
        prefillModal('primary')
        setIsAddressModalOpen(true);
      }
      else {
        setBaseModalData(basicUserInfo)
        setIsNormalModalOpen(true);
      }
    };

    return (
      <FormOutlined
        onClick={() => {
          setModalItem(item);
          setModalAddressType(addressType)
          showModal();
        }}
      />
    );
  }


  function handlePhoneEdit(phone) {
    const newBasicInfo = {
      "firstName": basicUserInfo.firstName,
      "lastName": basicUserInfo.lastName,
      "email": basicUserInfo.email,
      "phone": phone
    }
    fetchUpdateBasicInfo(newBasicInfo, navigate)
      .then(data => {
        refetchShippingInfo()
      })
    setIsNormalModalOpen(false)
  }

  function handleAddressItemEdit(firstName, lastName, email, phone, address1, address2, city, state, zip, addressType) {
    const new_shipping_info = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      streetAddress: address1,
      streetAddress2: address2,
      city: city,
      stateProvince: state,
      postalZipcode: zip,
      addressType: addressType
    }

    fetchUpdateShippingInfo(new_shipping_info, navigate)
      .then(data => {
        refetchShippingInfo()
      })
    setIsAddressModalOpen(false)
  }

  function UserInfoItem({ label, value, showEditButton, addressType }) {
    return (
      <div className="flex flex-col justify-start items-start w-full">
        <div className="flex flex-row w-full whitespace-nowrap text-md font-extrabold" style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>
          {label}
          {showEditButton && (
            <div className="flex flex-row justify-end w-full m-auto">
              <EditBtn item={label} isAddress={label.includes("Address")} addressType={addressType} />
            </div>
          )}
        </div>
        <div className="text-zinc-500 text-lg text-start pl-4" style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>{value}</div>
      </div>
    );
  }


  function OrderItem({ order }) {
    return (
      <div className="flex flex-row w-full bg-gray-100 rounded-lg space-x-2 p-2">
        <div className="w-3/5 self-center">
          <img src={order.imageSrc} alt="" />
        </div>
        <div className="w-full">
          <ul>
            <li className="text-black text-xl text-left font-bold" style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>
              {order.title}
            </li>
            <li className="text-left text-gray-600 text-md" style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>
              ${order.price.toFixed(2)}
            </li>
            <li className="text-left text-gray-600 text-md" style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>
              Type: {order.type}
            </li>
            <li className="text-left text-gray-600 text-md" style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>
              Color: {order.color}
            </li>
            <li className="text-left text-gray-600 text-md" style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>
              Size: {order.size}
            </li>
            <li className="text-left text-gray-600 text-md" style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>
              Status: {order.shippingStatus}
            </li>
           
          </ul>
        </div>
      </div>
    );
  }

  const handleModalInputChange = (field, value) => {
    setModalData(prev => ({ ...prev, [field]: value }));
  };
  const handleBaseModalInputChange = (field, value) => {
    setBaseModalData(prev => ({ ...prev, [field]: value }));
  };

  return (
    <div className="w-screen max-w-xl">
      <div className="px-5 py-14">
        <div className="user-info-container">
          <button
            onClick={() => navigate(-1)}
            className="back-button text-black-500 hover:text-blue-700"
          >
            <LeftCircleOutlined style={{ fontSize: '24px' }} />
          </button>
          <h1 className="text-2xl font-semibold">Account Information</h1>
        </div>
        <div className="flex flex-col px-5 items-start space-y-2">
          <Modal
            title={<p className="text-xl" style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>{`Edit ${modalItem}`}</p>}
            open={isNormalModalOpen}
            okButtonProps={{ style: { display: "none" } }}
            cancelButtonProps={{ style: { display: "none" } }}
            closeIcon={false}
            zIndex={40}
          >
            <h2 className='text-start text-lg mt-2' style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>New {modalItem}:<span className="text-red-600 ml-2">*</span></h2>
            <input id="modalPhone" className="border-2 border-neutral-300 w-full h-10 p-2 focus:outline-none focus:border-primary-500" placeholder="Phone Number" value={(baseModalData && baseModalData.phone) ? baseModalData.phone : ''}
            onChange={e => handleBaseModalInputChange('phone', e.target.value)} />

            {/*<Input id="modalPhone" placeholder="Phone Number" />*/}
            <div className="flex flex-row w-full justify-end mt-3">
              <button className="bg-gray-200 text-black-100 font-extrabold py-2 px-4 rounded-full mr-5" onClick={closeModal}>CANCEL</button>
              <button
                style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`, backgroundColor: `${process.env.REACT_APP_THEME_BG_COLOR}`}}
                className="bg-sky-600 text-zinc-100 font-extrabold py-2 px-4 rounded-full text-lg"
                onClick={() => { handlePhoneEdit(document.getElementById("modalPhone").value) }}
              >
                OK
              </button>
            </div>
          </Modal>

          <Modal
            title={`Edit ${modalItem}`}
            open={isAddressModalOpen}
            okButtonProps={{ style: { display: "none" } }}
            cancelButtonProps={{ style: { display: "none" } }}
            closeIcon={false}
            zIndex={40}
            width={'80%'}
          >
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
              <div>
                <h2 className='text-start' style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>FIRST NAME<span className="text-red-600 ml-2">*</span></h2>
                <input id="modalFirstName" className="border-2 border-neutral-300 w-full h-10 p-2 focus:outline-none focus:border-primary-500" placeholder="First Name" 
                value={modalData.firstName} onChange={e => handleModalInputChange('firstName', e.target.value)} />
              </div>
              <div>
                <h2 className='text-start' style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>LAST NAME<span className="text-red-600 ml-2">*</span></h2>
                <input id="modalLastName" className="border-2 border-neutral-300 w-full h-10 p-2 focus:outline-none focus:border-primary-500" placeholder="Last Name" 
                value={modalData.lastName} onChange={e => handleModalInputChange('lastName', e.target.value)} />
              </div>
              <div>
                <h2 className='text-start' style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>EMAIL<span className="text-red-600 ml-2">*</span></h2>
                <input id="modalEmail" className="border-2 border-neutral-300 w-full h-10 p-2 focus:outline-none focus:border-primary-500" placeholder="Email" 
                value={modalData.email} onChange={e => handleModalInputChange('email', e.target.value)} />
              </div>
              <div>
                <h2 className='text-start' style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>PHONE<span className="text-red-600 ml-2">*</span></h2>
                <input id="modalPhone" className="border-2 border-neutral-300 w-full h-10 p-2 focus:outline-none focus:border-primary-500" placeholder="Phone" 
                value={modalData.phone} onChange={e => handleModalInputChange('phone', e.target.value)} />
              </div>
              <div>
                <h2 className='text-start' style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>Address LINE 1<span className="text-red-600 ml-2">*</span></h2>
                <input id="modalAddress1" className="border-2 border-neutral-300 w-full h-10 p-2 focus:outline-none focus:border-primary-500" placeholder="Address Line 1" 
                value={modalData.address1} onChange={e => handleModalInputChange('address1', e.target.value)} />
              </div>
              <div>
                <h2 className='text-start' style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>Address LINE 2<span className="text-red-600 ml-2">*</span></h2>
                <input id="modalAddress2" className="border-2 border-neutral-300 w-full h-10 p-2 focus:outline-none focus:border-primary-500" placeholder="Address Line 2" 
                value={modalData.address2} onChange={e => handleModalInputChange('address2', e.target.value)} />
              </div>
              <div>
                <h2 className='text-start' style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>CITY<span className="text-red-600 ml-2">*</span></h2>
                <input id="modalCity" className="border-2 border-neutral-300 w-full h-10 p-2 focus:outline-none focus:border-primary-500" placeholder="City" 
                value={modalData.city} onChange={e => handleModalInputChange('city', e.target.value)} />
              </div>
              <div>
                <h2 className='text-start' style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>STATE<span className="text-red-600 ml-2">*</span></h2>
                <Select id="modalState"
                  placeholder="SELECT STATE"
                  defaultValue="AL"
                  style={{ width: "100%", height: "40px", marginBottom: "10px", padding:"0px", borderColor:"lightgrey"
                  ,borderWidth:"2px", boxShadow:"none", borderRadius:'0px'}}
                  onChange={(value) => { handleModalInputChange('state', value)}}
                  options={USStatesNames} 
                  value={modalData.state} />
              </div>
              <div>
                <h2 className='text-start' style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>ZIP CODE<span className="text-red-600 ml-2">*</span></h2>
                <input id="modalZipCode" className="border-2 border-neutral-300 w-full h-10 p-2 focus:outline-none focus:border-primary-500" placeholder="Zip Code" 
                value={modalData.zipCode} onChange={e => handleModalInputChange('zipCode', e.target.value)} />
              </div>
            </div>

            <div className="flex flex-row w-full justify-end mt-3">
                <button className="bg-gray-200 text-black-100 font-extrabold py-2 px-4 rounded-full mr-5" onClick={closeModal}>CANCEL</button>
                <button
                  style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`, backgroundColor: `${process.env.REACT_APP_THEME_BG_COLOR}`}}
                  className="bg-sky-600 text-zinc-100 font-extrabold py-2 px-4 rounded-full text-lg"
                  onClick={() => handleAddressItemEdit(
                    document.getElementById("modalFirstName").value,
                    document.getElementById("modalLastName").value,
                    document.getElementById("modalEmail").value,
                    document.getElementById("modalPhone").value,
                    document.getElementById("modalAddress1").value,
                    document.getElementById("modalAddress2").value,
                    document.getElementById("modalCity").value,
                    document.getElementById("modalState").currentValue ? document.getElementById("modalState").currentValue : "AL",
                    document.getElementById("modalZipCode").value,
                    modalAddressType
                  )}
                >
                  OK
                </button>
              </div>
          </Modal>

          <br /><br />
          <div className="text-black text-4xl font-bold pt-4" style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}}>
            {getName()}
          </div>

          <div className="border-2 h-0 border-zinc-500 w-full rounded-full"></div>

          <UserInfoItem label="Email" value={getEmail()} showEditButton={false} addressType="null" />
          <UserInfoItem label="Phone Number" value={getPhone()} showEditButton={true} addressType="null" />
          <UserInfoItem
            label="Primary Address"
            value={addressToString(findAddress('primary'), 1)}
            showEditButton={true}
            addressType="primary"
          />
          {/* <UserInfoItem
            label="Secondary Address"
            value={addressToString(findAddress('secondary'), 2)}
            showEditButton={true}
            addressType="secondary"
          /> */}
          {/* <UserInfoItem label="Class Of Graduation" value="1995" /> */}

          <div className="border-2 h-0 border-zinc-500 w-full rounded-full"></div>

          <div style={{fontFamily : `${process.env.REACT_APP_THEME_FONT_FAMILY}`}} className="text-black text-3xl font-bold">
            Order History:
          </div>

          {orderList.map((order, index) => {
            return (
              <LazyLoad>
                <OrderItem order={order} key={index} />
              </LazyLoad>);
          })}
        </div>
      </div>
    </div>
  );
}