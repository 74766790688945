import {
  forwardRef,
  useEffect,
  useState,
  useContext,
  useImperativeHandle,
  useRef,
} from "react";
import { Rnd } from "react-rnd";
import "../components/ZoomItem/ZoomItem.css";
import { AppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";
import DrophouseLogo from "../assets/logo_footer_t.png";

const R_image = require(`../${
  process.env.REACT_APP_COLLEGE_LOGO_PATH
    ? process.env.REACT_APP_COLLEGE_LOGO_PATH
    : process.env.REACT_APP_COLLEGE_LOGO_MASK_PATH
}`);
const ZoomItem = forwardRef(function Zoom(props, ref) {
  const pointDirections = ["ne", "nw", "se", "sw"];
  const [imageX, setImageX] = useState(105);
  const [imageY, setImageY] = useState(105);
  const [imageWidth, setImageWidth] = useState("59%");
  const [imageHeight, setImageHeight] = useState("59%");
  const [R_width, setRWidth] = useState(320);
  const [R_width_rend, setRWidth_rend] = useState(320);
  const [container_width, setConatinerWidth] = useState(128);
  const [container_width_rend, setConatinerWidth_rend] = useState(128);
  const [out_container_width, setOutContainerWidth] = useState(512);
  const [initOffset, setInitOffset] = useState(25);
  const { apparel } = useContext(AppContext);
  const zoomerImageRef = useRef(null);
  const navigate = useNavigate();

  function resetAllPositions() {
    if (window.innerWidth < 544) {
      // setConatinerWidth(64);
      // setRWidth(128);
      // setOutContainerWidth(256);
      // setInitOffset(50);
      // setImageX(32);
      // setImageY(32);
      // setConatinerWidth(((window.innerWidth / 100) * 60) / 2);
      setConatinerWidth(180);
      setConatinerWidth_rend(180);
      setRWidth_rend(300);
      setConatinerWidth_rend(310);
      // setConatinerWidth((window.innerWidth/100)*100);
      // setRWidth((window.innerWidth / 100) * 75);
      setRWidth(85);
      setOutContainerWidth(310);
      // setOutContainerWidth((window.innerWidth/100)*100)
      // setInitOffset(window.innerWidth / 100 + 27);
      setInitOffset(33);
      setImageX(63);
      // setImageWidth(65);
      setImageY(63);
    } else {
      setConatinerWidth(270);
      setRWidth(162);
      setImageHeight('59%');
      setImageWidth('59%');
      setRWidth_rend(450);
      setOutContainerWidth(512);
      setConatinerWidth_rend(512);
      setInitOffset(23);
      setImageX(95);
      setImageY(95);
    }
  }

  useEffect(() => {
    resetAllPositions();
  }, []);

  useImperativeHandle(
    ref,
    () => {
      return {
        getEditedImageRef() {
          return zoomerImageRef;
        },
        resetImagePosition() {
          return resetAllPositions();
        },
      };
    },
    []
  );

  function setZoomBoundaryStyle(apparel) {
    switch (apparel) {
      case "mug":
        return "mugZoomBoundary";
      case "cap":
        return "capZoomBoundary";
      default:
        return "zoomBoundary";
    }
  }

  function setZoomerInnerBoundaryStyle(apparel) {
    switch (apparel) {
      case "mug":
        return "mugInnerZoomBoundary";
      default:
        return "zoomInnerBorder";
    }
  }

  function isPercentage(input) {
    var pattern = /^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)%$/;
    return pattern.test(input);
  }

  function calculate_R_BackgroundPosition(R_width, container_width) {
    let ratio = R_width / container_width;
    return `${imageX * ratio - R_width * 0.5 + 20.7}px ${imageY * ratio - R_width * 0.5 + 20.7}px`
  }

  function calculate_R_BackgroundPosition_mbl(R_width, container_width) {
    let ratio = R_width / container_width;
    return `${imageX * ratio - R_width * 0.5 + 13.7}px ${imageY * ratio - R_width * 0.5 + 13.7}px`
  }

  function calculate_R_BackgroundSize(R_width, container_width) {
    let width = 0;
    let height = 0;
    if (isPercentage(imageWidth)) {
      width = R_width;
      height = R_width;
    } else {
      let ratio = R_width / container_width;
      width = parseInt(imageWidth) * ratio;
      height = parseInt(imageHeight) * ratio;
    }
    return `${width}px ${height}px`
  }

  function calculate_R_BackgroundSize_mbl(R_width, container_width) {
    let width = 0;
    let height = 0;
    if (isPercentage(imageWidth)) {
      width = R_width  + 42.5;
      height = R_width  + 42.5;
    } else {
      let ratio = R_width / container_width;
      width = parseInt(imageWidth) * ratio ;
      height = parseInt(imageHeight) * ratio ;
    }
    return `${width}px ${height}px`
  }

  const [alignment, setAlignment] = useState("Edit");

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  // for rendering HD image
  function calculate_R_BackgroundPosition_rend(R_width_rend, container_width_rend) {
      let ratio = R_width_rend / container_width_rend;
      let newimageX = imageX * 1.8;
      let newimageY = imageY * 1.8;
      return `${newimageX * ratio - R_width_rend * 0.38 +25}px ${newimageY * ratio - R_width_rend * 0.38 + 25}px`
    }
  
    function calculate_R_BackgroundPosition_mbl_rend(R_width_rend, container_width_rend) {
      let ratio = R_width_rend / container_width_rend;
      let newimageX = imageX * 1.8;
      let newimageY = imageY * 1.8;
      return `${newimageX * ratio - R_width_rend +190}px ${newimageY * ratio - R_width_rend + 190}px`
    }
  
    function calculate_R_BackgroundSize_rend(R_width_rend, container_width_rend) {
      let width = 0;
      let height = 0;
      if (isPercentage(imageWidth)) {
        width = R_width_rend;
        height = R_width_rend;
      } else {
        let ratio = R_width_rend / container_width_rend;
        let newimageWidth = imageWidth * 2;
        let newimageHeight = imageHeight * 2;
        width = parseInt(imageWidth) * ratio * 1.9;
        height = parseInt(imageHeight) * ratio  * 1.9;
      }
      return `${width}px ${height}px`
    }
  
    function calculate_R_BackgroundSize_mbl_rend(R_width_rend, container_width_rend) {
      let width = 0;
      let height = 0;
      if (isPercentage(imageWidth)) {
        width = R_width_rend;
        height = R_width_rend;
      } else {
        let ratio = R_width_rend / container_width_rend;
        width = parseInt(imageWidth) * ratio * 1.666;
        height = parseInt(imageHeight) * ratio * 1.666;
      }
      return `${width}px ${height }px`
    }
  
  // End
  // console.log(props.TshirtImageSrc)
  return (
    <div
      id="zoomer-new"
      className={`relative z-[4] mt-[10%] grid w-full h-full ${props.isZoomEnabled ? '' : 'hidden'}`}
      // style={{height: `460px`,width: `460px`}}
    >
       <div className="nav-zoomer">
        {/* <button onClick={() => navigate(-1)} className={`absolute`}>
        <svg className="back-arrow-zoomer" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512"><path fill="none" stroke="black" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M244 400L100 256l144-144M120 256h292"/></svg>
        </button> */}
        <img
            className="h-[2rem] md:h-[3rem] mx-auto"
            src={DrophouseLogo}
            alt="logo"
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
      <div className={`grid grid-cols-2 md:grid-cols-2 justify-items-center items-center fixed w-full ${!(window.innerWidth <= 544) ? `` : `hidden`}`} style={{top: `50px`,width: `100vw`,height: `30px` ,backgroundColor: `white`,zIndex: `99999999`,color: `rgb(128, 0, 0)`,fontSize: `19px`}}>
        <div className="grid" style={{width: `100%`,height: `30px`,textAlign: `center`, border: `1px solid grey`}}>Preview</div>
        <div className="grid" style={{width: `100%`,height: `30px`,textAlign: `center`, border: `1px solid grey`,borderLeft: `none`}}>Edit</div>
      </div>
      <div className={`grid ${!(window.innerWidth <= 1024)? `grid-cols-2 `:`grid-cols-1`}  justify-items-center items-center relative w-full`} style={{width: `100vw`,height: `87vh`}}>
      <div className={`preview-div`}>
      <div className={`grid ${!(window.innerWidth <= 544) ? `hidden` : ``}`} style={{color: `rgb(128, 0, 0)`,width: `100%`,height: `30px`,position: `absolute`,top: `-10%`,textAlign: `center`, border: `1px solid grey`}}>Preview</div>
      <div className={`${props.TshirtImageSrc ? props.TshirtImageSrc.split('/').pop().split('_')[0] : ``}-R-Src-Img bg-repeat ${(process.env.REACT_APP_USERTYPE == 'STUDENT' ? `R_Preview_Std`:`R_Preview`)} outline-dashed outline-offset outline-green-500`}
        style={{
          width: `${R_width}px`,
          height: `${R_width}px`,
          backgroundImage: `url(${props.imageSrc})`,
          backgroundPosition: `${!(window.innerWidth < 544) ? calculate_R_BackgroundPosition(R_width,container_width) : calculate_R_BackgroundPosition_mbl(R_width,container_width)}`,
          backgroundSize: `${!(window.innerWidth < 544)? calculate_R_BackgroundSize(R_width,container_width) : calculate_R_BackgroundSize_mbl(R_width / 2,container_width / 2)}`,
          position: `absolute`,
        }}
         >
        {/* <img
          draggable={false}
          className={`w-full h-full`}
          src={R_image}
          alt="R image"
        /> */}
      </div>
      <div className={`${props.TshirtImageSrc ? props.TshirtImageSrc.split('/').pop().split('_')[0]: `` }-preview preview-new-zoomer relative bg-no-repeat bg-contain bg-center  z-40`}
          style={{
            backgroundImage: `url(${props.TshirtImageSrc})`,
            top: `0`,
            left: `0`,
          }}>

        </div>
      </div>
      <div className={`zoomer-interactive-div relative ${!(window.innerWidth < 544) ? `hei-460` : ``}`}
      //  style={{height: `460px`,width: `460px`}}
       >
      <div className={`grid ${!(window.innerWidth <= 544) ? `hidden` : ``}`} style={{color: `rgb(128, 0, 0)`,width: `100%`,height: `30px`,textAlign: `center`, border: `1px solid grey`,position: `absolute`,top: `-17%`}}>Edit</div>
        <span className="zoomer-span">Adjust the image inside the red dotted square !</span>
      <div
        className="rectangle-img-border"
        style={{
          width: `${imageWidth}`,
          height: `${imageHeight}`,
          top: `${imageY}px`,
          left: `${imageX}px`,
          position: `absolute`,
          zIndex: `9999`,
          backgroundColor: `transparent`,
          border: `3px dotted red`,
          pointerEvents: `none`
        }}
        ><div class="control-point point-ne"></div><div class="control-point point-nw"></div><div class="control-point point-se"></div><div class="control-point point-sw"></div>
      </div>
      {/* dotted lines on zoomer */}
      {/* <canvas className={`canvas-R ${(window.innerWidth < 544) ? `hidden` : ``}`} style={{ */}
      <canvas className={`canvas-R`} style={{
        backgroundImage: `url(${props.imageSrc})`,
        backgroundPosition: `${!(window.innerWidth < 544) ? calculate_R_BackgroundPosition_rend(R_width_rend,container_width_rend) : calculate_R_BackgroundPosition_mbl_rend(R_width_rend,container_width_rend)}`,
        backgroundSize: `${!(window.innerWidth < 544)? calculate_R_BackgroundSize_rend(R_width_rend,container_width_rend) : calculate_R_BackgroundSize_mbl_rend(R_width_rend,container_width_rend)}`,
        opacity: `1`,
        height: `${(window.innerWidth < 544) ? `300px`: `450px`}`,
        width: `${(window.innerWidth < 544) ? `300px`: `450px`}`,
        top: `0`,
        left: `0`,
      }}
      ref={zoomerImageRef}
      ></canvas>
      {/* <div className="canvas-R z-20" style={{
              height: `${(window.innerWidth < 544) ? `300px`: `450px`}`,
              width: `${(window.innerWidth < 544) ? `300px`: `450px`}`,
              left: `0`,
              top: `-400px`,
              zIndex: `9999`,
              position: `absolute`
      }}></div> */}
      <canvas className={`${(process.env.REACT_APP_USERTYPE == 'STUDENT') ?  `canvas-R-Std`:  `canvas-R`} z-20`}
      style={{
        backgroundImage: `url(${props.mockImageSrc})`
      }}
      ></canvas>
      <canvas className="whitespace-top"></canvas>
      <canvas className="whitespace-left"></canvas>
      <canvas className="whitespace-right"></canvas>
      <canvas className="whitespace-bottom"></canvas>
      <div
        className={`mx-auto relative bg-repeat w-full h-full`}
        style={{
          backgroundImage: `url(${props.imageSrc})`,
          backgroundPosition: `${imageX}px ${imageY}px`,
          backgroundSize: `${imageWidth} ${imageHeight}`,
          // position: "fixed",
          height: `${!(window.innerWidth <= 544) ? `460px` : `310px`}`,
          width: `${!(window.innerWidth <= 544) ? `460px` : `310px`}`,
        }}
        
      ></div>
      <Rnd
        data-html2canvas-ignore="true"
        className={`z-10 flex bg-repeat-round`}
        bounds={"parent"}
        size={{ width: `${imageWidth}`, height: `${imageHeight}` }}
        style={{
          backgroundSize: "cover",
          backgroundImage: `url(${props.imageSrc})`,
          top: `-${initOffset}%`,
          left: `-${initOffset}%`,
          border: `1px dotted #800000`,
        }}
        position={{ x: imageX, y: imageY }}
        maxHeight={"100%"}
        maxWidth={"100%"}
        enableResizing={{
          bottom: false,
          bottomLeft: true,
          bottomRight: true,
          left: false,
          right: false,
          top: false,
          topLeft: true,
          topRight: true,
        }}
        onDrag={(e, d) => {
          setImageX(imageX + d.deltaX);
          setImageY(imageY + d.deltaY);
        }}
        onResize={(e, direction, ref, delta, position) => {
          setImageWidth(ref.offsetWidth + "px");
          setImageHeight(ref.offsetHeight + "px");
          setImageX(position.x);
          setImageY(position.y);
        }}
      >
        {pointDirections.map((item, index) => (
          <div key={index} className={`control-point point-${item}`}></div>
        ))}
      </Rnd>
      </div>
      </div>
    </div>
  );
});

export default ZoomItem;
